import React from 'react';
import { StaticImage } from "gatsby-plugin-image";

import Layout from '../components/layout';
import Seo from '../components/seo';

const NotFoundPage = (props) => (
  <Layout location={props.location}>
    <Seo title={'404'} />
    <div className='error'>
      <div style={{ textAlign: 'center' }}>
        <div className='brand'>
          <h1>4</h1>
          <StaticImage src="../images/brand.png" fullWidth height={150} placeholder="blurred" alt='0' />
          <h1>4</h1>
        </div>
        <span className='subtitle'>Pizza non trovata</span>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
